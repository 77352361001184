'use strict';

(function() {
  class AddEditBladeCtrl {
    constructor($scope, Auth, Site, $uibModal, $state, usSpinnerService) {
      this.$scope = $scope;
      this.Site = Site;
      this.$uibModal = $uibModal;
      this.turbine = {};
      this.loading = false;
      this.usSpinnerService = usSpinnerService;
      Auth.isLoggedInAsync((loggedIn)=> {
        if (loggedIn) {
          // this.init();
        } else {
          $state.go('login');
        }
      });
    }

    addBlade() {
      return blade=> {
        this.$scope.turbine.blades.push(blade.data);
        this.$scope.turbine.blades.sort((a, b) => a.position - b.position);
      }
    }

    openAddBladeModal(data = {}, update = false) {
      var modalInstance = this.$uibModal.open({
        size: 'lg',
        templateUrl: 'components/modal/blade/addEditBladeModal/addEditBladeModal.html',
        controller: 'AddEditBladeModalCtrl',
        controllerAs: 'aEBlade',
        backdrop: 'static',
        keyboard: false,
        resolve: {
          options: {
            title: 'Add New Blade',
            turbine: this.$scope.turbine
          }
        }
      });
      modalInstance.result.then(this.addBlade());
    }

    startSpin(spinner) {
      this.usSpinnerService.spin(spinner);
    }

    stopSpin(spinner) {
      this.usSpinnerService.stop(spinner);
    }
  }

  angular.module('windmanagerApp')
    .controller('AddEditBladeCtrl', AddEditBladeCtrl);
})();

